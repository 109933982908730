import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProvider } from '../providers/user';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class AuthCheckGuard implements CanActivate, CanActivateChild {

  constructor(
    public user: UserProvider,
    private router: Router
  ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.user.authState$.pipe(
          filter(res => res !== null),
          map(user => user ? true : this.router.parseUrl('authentication/login'))
      );
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.user.authState$.pipe(
            filter(res => res !== null),
            map(user => user ? true : this.router.parseUrl('authentication/login'))
        );
    }

}
