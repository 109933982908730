/*

	restaurant.ts

	Provider to work with restaurant

	Authors:	Ilia Ashmarin (mail[0]harduino.com),
				Matteo Carrara (team@flaaash.it)

	History:	2018.06.26 - File created

*/
import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CfgProvider} from './cfg';
import {HelpersProvider} from './helpers';
import {ToastProvider} from './toast';
import {ApiProvider} from './api';
import {UserProvider} from './user';
import {LogProvider} from './log';

@Injectable()
export class CourierManagerProvider {

    info = null;
    info$ = new BehaviorSubject<any>(null);

    constructor(
        public cfg: CfgProvider,
        public helpers: HelpersProvider,
        public toast: ToastProvider,
        public api: ApiProvider,
        public user: UserProvider,
        public log: LogProvider
    ) {
        // this.user.restaurant = this;
    }

    ngUnsubscribe = new Subject<void>();

    async init() {
        this.user.info$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((info) => {
                if (info && info.shop && !this.helpers.isEmpty(info.shop)) {
                    this.info = info.shop;
                    this.info$.next(this.info);
                }
            })
        ;
    }

    todayOrders = null;
    todayOrders$ = new BehaviorSubject<any>(null);

    async actualizeOrders(cityId) {
        await this.api.actualize.call(
            this,
            {
                method: 'courierManager/getOrders',
                data: {cityId: cityId},
                varName: 'todayOrders'
            }
        );
    }

    openHours = null;
    openHours$ = new BehaviorSubject<any>(null);

    async actualizeOpenHours(restaurantId) {
        await this.api.actualize.call(
            this,
            {
                method: 'courierManager/restaurant/hours/get',
                data: {
                    restaurantId: restaurantId
                },
                varName: 'openHours'
            }
        );
    }

    async updateOpenHours(newData, restaurantId) {
        await this.api.updateData.call(
            this,
            {
                method: 'courierManager/restaurant/hours/set',
                data: {
                    openHours: newData,
                    restaurantId: restaurantId
                },
                varName: 'openHours'
            }
        );
    }


    availableQuarters = null;
    availableQuarters$ = new BehaviorSubject<any>(null);

    async actualizeAvailableQuarters(restaurantId) {
        await this.api.actualize.call(
            this,
            {
                method: 'courierManager/restaurant/quarters/get',
                data: {
                    data: restaurantId
                },
                varName: 'availableQuarters'
            }
        );
    }

    async updateAvailableQuarters(newData) {
        await this.api.updateDataAvailableQuarters.call(
            this,
            {
                method: 'courierManager/restaurant/quarters/set',
                data: newData,
                varName: 'availableQuarters'
            }
        );
    }

    secondaryProducts = null;
    secondaryProducts$ = new BehaviorSubject<any>(null);

    async actualizeSecondaryProducts(restaurantId) {
        await this.api.actualize.call(
            this,
            {
                method: 'courierManager/restaurant/product/getSecondaryProducts',
                data: {
                    restaurantId: restaurantId
                },
                varName: 'secondaryProducts'
            }
        );
    }

    menu = null;
    menu$ = new BehaviorSubject<any>(null);

    async actualizeMenu(restaurantId) {
        await this.api.actualize.call(
            this,
            {
                methodType: 'postAsync',
                method: 'courierManager/getRestaurantMenu',
                data: {
                    restaurantId: restaurantId
                },
                varName: 'menu',
                sendSubscribers: false
            }
        );

        // process products
        for (let i = 0; i < this.menu.length; i++) {
            let menuItem = this.menu[i];
            for (let j = 0; j < menuItem.products.length; j++) {
                let productItem = menuItem.products[j];
                productItem = this.prepareProduct(productItem);
            }
        }
        // send to subscribers
        this.menu$.next(this.menu);
    }

    async productDelete(productId, restaurantId) {
        try {
            const responseData = await this.api.postAsync(
                'courierManager/restaurant/deleteProduct',
                {
                    restaurantId: restaurantId,
                    productId: productId
                }
            );

            // process response from API-server
            if (responseData.status === 'success') {
                this.actualizeMenu(restaurantId);
            } else {
                throw new Error(responseData.message);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async secondaryProductDelete(secondaryProductId, restaurantId) {
        try {
            const responseData = await this.api.postAsync(
                'courierManager/restaurant/product/deleteSecondaryProduct',
                {
                    secondaryProductId: secondaryProductId,
                    restaurantId: restaurantId
                }
            );
            // process response from API-server
            if (responseData.status === 'success') {
                this.actualizeSecondaryProducts(restaurantId);
            } else {
                throw new Error(responseData.message);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    prepareRestaurant(restaurant) {
        if (!restaurant.restaurantLogo || restaurant.restaurantLogo.substr(0, 4) !== 'http') {
            if (restaurant.restaurantLogo) {
                restaurant.restaurantLogo = this.cfg.url.api + restaurant.restaurantLogo;
            } else {
                restaurant.restaurantLogo = this.cfg.url.placeholder.productImage;
            }
        }

        if (!restaurant.restaurantCover || restaurant.restaurantCover.substr(0, 4) !== 'http') {
            if (restaurant.restaurantCover) {
                restaurant.restaurantCover = this.cfg.url.api + restaurant.restaurantCover;
            } else {
                restaurant.restaurantCover = this.cfg.url.placeholder.restaurantCover;
            }
        }

        return restaurant;
    }

    prepareProduct(product) {
        // form product's image
        if (!product.image || product.image.substr(0, 4) !== 'http') {
            if (product.image) {
                product.image = this.cfg.url.api + product.image;
            } else {
                product.image = this.cfg.url.placeholder.productImage;
            }
        }

        return product;
    }
}
