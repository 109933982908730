/*

	format-hour.ts

	Format int-value of hour to view "09:00"

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.08.24 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';
import { HelpersProvider } from '../providers/helpers';

@Pipe({
	name: 'formatHour',
})
export class FormatHourPipe implements PipeTransform {
	constructor(public helpers: HelpersProvider) {}

	transform( value ): any {
		return this.helpers.hourToTime(value);
	}
}
