/*

	city.ts

	Provider for city

	Authors:	Matteo Carrara (team@flaash.it)

	History:	2018.04.12 - File created

*/
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CfgProvider } from './cfg';
import { SettingsProvider } from './settings';
import { HelpersProvider } from './helpers';
import { LogProvider } from './log';
import { ApiProvider } from './api';
import { ToastProvider } from './toast';

@Injectable()
export class CityProvider {

	cityID;
	citySelected;
	settingsData;
	info = {
		_id: 0,
		cityName: ''
	};
	info$ = new BehaviorSubject<any>(null);

	constructor(
		public cfg: CfgProvider,
		public log: LogProvider,
		public settings: SettingsProvider,
		public helpers: HelpersProvider,
		public api: ApiProvider,
		public toast: ToastProvider
	){}


	// initialise provider
	ngUnsubscribe = new Subject<void>();
	async init() {
		// subscribing for getting actual settings list
		this.settings.data$
			.pipe( takeUntil( this.ngUnsubscribe ) )
			.subscribe((settings) => {
				this.settingsData = settings;
				// extracting city info and sending to subscribers
				if( this.settingsData['citySelected'] ) {
					try {
						this.info = this.settingsData['citySelected'];
						this.info$.next( this.info );
					} catch(e) {
						this.log.debug( 'failed to parse local city info', e.message || e );
					}
				}
			})
		;
	}

	async getCitySelected() {
		return this.info.cityName ;
	}

	async getCityId(){
		return this.info._id;
	}

	// update zone's info
	async zoneUpdateInfo(zoneInfo) {
		try {
			const data = await this.api.postAsync('courierManager/updateZone', {
				cityId:					zoneInfo.cityId,
				zoneId:					zoneInfo._id,
				zoneName:				zoneInfo.zoneName,
				couriersAssigned:		zoneInfo.couriersAssigned,
				restaurantsAssigned:	zoneInfo.restaurantsAssigned,
				nearbyZone:				zoneInfo.nearbyZone
			});
			if(data.status === 'success') {
				// zoneInfo = data.result;
			} else {
				this.toast.create(`Failed to update zone: ${data.message}`);
			}
		} catch(e) {
			this.log.debug(e);
		}
	}

	// delete zone
	async zoneDelete(zoneInfo) {
		try {
			const data = await this.api.postAsync('courierManager/deleteZone', {
				zoneId: this.helpers.getId(zoneInfo._id),
			});
			if (data.status !== 'success') {
				this.toast.create(`Failed to delete zone: ${data.message}`);
			}
		} catch(e) {
			this.log.debug(e);
		}
	}
}
