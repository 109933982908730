import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicStorageModule} from '@ionic/storage';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ImagePicker} from '@ionic-native/image-picker/ngx';
import {TabsPage} from './tabs/tabs.page';

//PROVIDERS
import {HTTP} from '@ionic-native/http/ngx';
import {ApiProvider} from './providers/api';
import {CfgProvider} from './providers/cfg';
import {HelpersProvider} from './providers/helpers';
import {LogProvider} from './providers/log';
import {SettingsProvider} from './providers/settings';
import {ToastProvider} from './providers/toast';
import {CityProvider} from './providers/city';
import {UserProvider} from './providers/user';
import {CourierProvider} from './providers/courier';
import {CourierManagerProvider} from './providers/courierManager';
import {DeliveryProvider} from './providers/delivery';
import {RedirectionProvider} from './providers/redirection';

//PIPE
import {PipesModule} from './pipes/pipes.module';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Device} from '@ionic-native/device/ngx';
import {ImagePickerMock} from './mocks/image-picker';
import {Base64} from '@ionic-native/base64/ngx';
import {PushProvider} from './providers/push';
import {AuthCheckGuard} from './guards/auth-check.guard';
import {CityCheckGuard} from './guards/city-check.guard';


@NgModule({
    declarations: [
        AppComponent,
        TabsPage
    ],
    entryComponents: [
        TabsPage
    ],
    imports: [
        PipesModule,
        BrowserModule,
        FormsModule,
        CommonModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        Nl2BrPipeModule,
        AppRoutingModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        HTTP,
        RedirectionProvider,
        ApiProvider,
        CfgProvider,
        HelpersProvider,
        LogProvider,
        SettingsProvider,
        ToastProvider,
        DeliveryProvider,
        CallNumber,
        CityProvider,
        Base64,
        UserProvider,
        CourierProvider,
        PushProvider,
        Device,
        CourierManagerProvider,
        ImagePicker,
        AuthCheckGuard,
        CityCheckGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
