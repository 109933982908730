/*

	filter-has-orders-for-quarter.ts

	Pipe to filter couriers by selected quarter

	Authors:	Ilia Ashmarin (mail@harduino.com)

	History:	2018.05.29 - File created

*/
import { Pipe, PipeTransform } from '@angular/core';
import { HelpersProvider } from '../providers/helpers';

@Pipe({
	name: 'filterHasOrdersForQuarter'
})
export class FilterHasOrdersForQuarterPipe implements PipeTransform {
	constructor(
		public helpers: HelpersProvider
	){}

	transform(couriers: any[], orders:any[], quarter:any ): any[] {
		return couriers.filter( courier => {
			return orders.filter( order => {
				return order.CourierId === courier.CourierId && this.helpers.isDeliveryBelongsQuarter(order.DeliveryTime, quarter);
			}).length > 0;
		});
	}
}
