/*

	helpers.ts

	Helpers

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.05.04 - File created

*/
import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class HelpersProvider {
	isBrowser() {
		return document.URL.includes('https://') || document.URL.includes('http://');
	}

	createRange(number){
		var items: number[] = [];
		for(var i = 1; i <= number; i++){
			items.push(i);
		}
		return items;
	}

	// form object with key=id if array item
	getIndexById(arr) {
		let result = {};
		if(arr && arr.length) {
			for(let i = 0;i < arr.length;i++) {
				let item = arr[i];
				if(item && item._id) {
					result[item._id] = item;
				}
			}
		}
		return result;
	}

	arraysEqual(a, b) {
		a = this.clone(a);
		b = this.clone(b);

		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length != b.length) return false;

		// normalize order
		a.sort();
		b.sort();

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}

	arrayPush(arr, value) {
		let alreadyExists = false;
		for(let i = 0;i < arr.length;i++) {
			if(arr[i] == value) {
				alreadyExists = true;
				break;
			}
		}

		if(!alreadyExists) {
			arr.push(value);
		}
	}
	
	arrayPull(arr, value) {
		let index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		}
	}


	roundPrice(value) {
		return parseFloat(value).toFixed(2);
	}


	getUnixWithTZOffset( unix ) {
		return unix + moment.unix(unix).utcOffset() * 60
	}

	base64UriAsData(dataURI) {
		var BASE64_MARKER = ';base64,';
		var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
		var base64 = dataURI.substring(base64Index);
		return base64;
	}

	// check that delivery time period (like "12:13 PM - 12:28 PM") is belongs to selected quarter (like "11:00 PM - 11:15 PM")
	isDeliveryBelongsQuarter( delivery, quarter ) {
		let deliveryMinutesAfterMidnight = this.parseQuarter(delivery);
		let quarterMinutesAfterMidnight = this.parseQuarter(quarter);

		let res =
			deliveryMinutesAfterMidnight.from >= quarterMinutesAfterMidnight.from
				&&
			deliveryMinutesAfterMidnight.from < quarterMinutesAfterMidnight.to
		;

		/*console.log( delivery, deliveryMinutesAfterMidnight, quarter, quarterMinutesAfterMidnight );
		if( res ) {
			console.log( delivery, deliveryMinutesAfterMidnight, quarter, quarterMinutesAfterMidnight );
		}*/
		return res;
	}

	// parse quarter string (like "12:13 PM - 12:28 PM") to minutes after midnight (from-to)
	parseQuarter( quarter ) {
		let res = [0,0];

		let timesList = quarter.replace(/\s/g, '').split('-');
		for( let i = 0;i < timesList.length;i++ ) {
			res[i] = this.parseMinutesAfterMidnight( timesList[i] );
		}

		return {
			from: res[0],
			to: res[1]
		};
	}

	// parse 24-h time to minutes after midnight
	parseMinutesAfterMidnight( timeRaw ) {
		let time24 = moment( timeRaw, ["h:mm A"]).format("HH:mm");
		let [hours, minutes] = time24.replace(/[^0-9:]/g,'').split(':').map( (val) => parseInt(val) );
		return hours*60 + minutes;
	}

	isEmpty( item ) {
		if( this.isNumeric(item) ) {
			return item.length > 0;
		} else {
			for( var index in item ) {
				if( item[index] != null ) {
					return false;
				}
			}
			return true;
		}
	}

	isNumeric(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	isFine( item ) {
		return typeof(item) != 'undefined' && item !== null && item !== '';
	}

	randomGet(str) {
		return str+'?'+Math.random().toString(36).substr(0, 5);
	}

	fillZero( str, zero_count, symbol='0', right_join=false ) {
		var zeros = '';
		if( typeof(str) != 'string' ) {
			str = str.toString();
		}

		if( str.length >= zero_count ) {
			return str;
		}

		for(var i = str.length;i < zero_count;i++) {
			zeros += symbol;
		}

		if( right_join ) {
			return str+zeros;
		} else {
			return zeros+str;
		}
	}

	hourToTime(hour) {
		return `${this.fillZero(hour.toString(), 2)}:00`;
	}

	basename(path, suffix = null) {
		//  discuss at: http://locutus.io/php/basename/
		// original by: Kevin van Zonneveld (http://kvz.io)
		// improved by: Ash Searle (http://hexmen.com/blog/)
		// improved by: Lincoln Ramsay
		// improved by: djmix
		// improved by: Dmitry Gorelenkov
		//   example 1: basename('/www/site/home.htm', '.htm')
		//   returns 1: 'home'
		//   example 2: basename('ecra.php?p=1')
		//   returns 2: 'ecra.php?p=1'
		//   example 3: basename('/some/path/')
		//   returns 3: 'path'
		//   example 4: basename('/some/path_ext.ext/','.ext')
		//   returns 4: 'path_ext'

		var b = path
		var lastChar = b.charAt(b.length - 1)

		if (lastChar === '/' || lastChar === '\\') {
			b = b.slice(0, -1)
		}

		b = b.replace(/^.*[/\\]/g, '')

		if (typeof suffix === 'string' && b.substr(b.length - suffix.length) === suffix) {
			b = b.substr(0, b.length - suffix.length)
		}

		return b
	}

	getExt( filename ) {
		return filename.split('.').pop().toLowerCase();
	}

	// return item's element id from array, which can be represented as object or string
	getId(item, index='_id') {
		let result = null;

		if(typeof item == 'string') {
			result = item;
		} else {
			if(item[index]) {
				if(item[index]['_id']) {
					result = item[index]['_id'];
				} else {
					result = item[index].toString();
				}
			}
		}
		return result;
	}

	// group quarters by hour
	groupQuarters( quarters ) {
		let res = {};
		for( let i = 0;i < quarters.length;i++ ) {
			let quarter = quarters[i];
			// console.warn( quarter );
			try {
				// form required data
				let hour = this.fillZero( quarter.substr(0, quarter.indexOf(':')), 2 );

				// save result
				if( typeof res[hour] == 'undefined' ) {
					res[hour] = new Array();
				}
				res[hour].push(quarter);
			} catch(e) {}
		}
		return res;
	}


	// deep clone
	clone(item) {
		var helpers = this;
		if (!item) { return item; } // null, undefined values check

		var types = [ Number, String, Boolean ],
		result;

		// normalizing primitives if someone did new String('aaa'), or new Number('444');
		types.forEach(function(type) {
			if (item instanceof type) {
				result = type( item );
			}
		});

		if (typeof result == "undefined") {
			if (Object.prototype.toString.call( item ) === "[object Array]") {
				result = [];
				item.forEach(function(child, index, array) {
					result[index] = helpers.clone( child );
				});
			} else if (typeof item == "object") {
				// testing that this is DOM
				if (item.nodeType && typeof item.cloneNode == "function") {
					result = item.cloneNode( true );
				} else if (!item.prototype) { // check that this is a literal
					if (item instanceof Date) {
						result = new Date(item);
					} else {
						// it is an object literal
						result = {};
						for (var i in item) {
							result[i] = helpers.clone( item[i] );
						}
					}
				} else {
					// depending what you would like here,
					// just keep the reference, or create new object
					// if (false && item.constructor) {
						// would not advice to do that, reason? Read below
						// result = new item.constructor();
					// } else {
						result = item;
					// }
				}
			} else {
				result = item;
			}
		}
		return result;
	}
}
