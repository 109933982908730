import { Component, OnInit } from '@angular/core';
import { Platform, NavController, AlertController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//PROVIDERS
import { LogProvider } from '../providers/log';
import { ApiProvider } from '../providers/api';
import { RedirectionProvider } from '../providers/redirection';
import { CfgProvider } from '../providers/cfg';
import { SettingsProvider } from '../providers/settings';
import { ToastProvider } from '../providers/toast';
import { CityProvider } from '../providers/city';
import { UserProvider } from '../providers/user';
import { CourierManagerProvider } from '../providers/courierManager';
import { HelpersProvider } from '../providers/helpers';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit{

  tabsPlacement = 'bottom';
  tabsLayout = 'icon-top';
  plat;
  settingsData;
  todayOrders = null;
  citySelected;
  cityID;
  orders = new Array();

  constructor(
    public plt: Platform,
    public navCtrl: NavController,
    public log: LogProvider,
    public api: ApiProvider,
    public redirection: RedirectionProvider,
    public cfg: CfgProvider,
    public settings: SettingsProvider,
    public alertCtrl: AlertController,
    public toast: ToastProvider,
    public user: UserProvider,
    public router: Router,
    public city: CityProvider,
    public courierManager: CourierManagerProvider,
    public helpers: HelpersProvider
  ) {
    this.plat = this.plt.is('mobile');
  }

  ngUnsubscribe: Subject<void> = new Subject<void>();
  ngOnInit() {
    if(!this.plat){
      // subscribing for getting actual settings list
    		this.settings.data$
    			.pipe(
    			    takeUntil( this.ngUnsubscribe )
                )
    			.subscribe(async (settings) => {
    				this.settingsData = settings;
            if(this.settingsData.citySelected){
              this.cityID = this.settingsData.citySelected._id;
              this.citySelected = this.settingsData.citySelected.cityName;
            }

    			})
    		;
    }

  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
