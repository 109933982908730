import { environment } from './../../environments/environment.prod';
/*

	cfg.ts

	Provider to work with config

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.04.06 - File created

*/
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subject, BehaviorSubject  } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

import { HelpersProvider } from './helpers';

@Injectable()
export class CfgProvider {

	debug = true;
	initCompleted = false;

	url = {
		api: this.getApiUri(),

		placeholder: {
			productImage: '../../assets/imgs/placeholder.png',
			restaurantLogo: '../../assets/imgs/placeholder.png',
			restaurantCover: '../../assets/imgs/placeholder.png'
		},

		img: {
			lunch: '../../assets/imgs/sun.png',
			dinner: '../../assets/imgs/moon.png',
		}
	};

	txt = {
		orderStates: {
			'0': 'Non accettato',
			'1': 'Accettato',
			'2': 'Processato',
			'3': 'Visto dal Risorante',
			'4': 'In preprarazione',
			'5': 'In consegna',
			'6': 'Consegnato',
			'7': 'Rimborsato'
		}
	};



	timeout = {
		toast: 3000
	};

	settingsPrefix = 'mngr';
	settings_rows = {
		welcomeSlideComplete : false,
		cart: {},
		xAuthHeader: '',
		tempUserId: '',
		userInfo: '',
		lastEmail: '',
		server: '',
		citySelected : '',
		deliveryInfo: {
			deliveryType: 'delivery',
			addressInfo: {
				address: '',
				cityId: '',
				houseNumber: ''
			}
		}
	};

		defaults = {
		pageLoginned: '',
		img: {
			maximumImagesCount: 1,
			quality: 100,
			outputType: 1 // base64 string
		}
	};
	productImageDimensions = {
			width: 300,
			height: 300
		};

		coverImageDimension = {
			width: 300,
			height: 200
		};

	strings = {
		openHours: {
			openHourLunch:		'Orario Apertura Pranzo',
			closeHourLunch:		'Orario Chiusura Pranzo',
			openHourDinner:		'Orario Apertura Cena',
			closeHourDinner:	'Orario Chiusura Cena'
		}
	};

	// this value will be assigned from app.component
	// pagesMap;

	internetConnection$ = new Subject();
	constructor(
		public helpers: HelpersProvider,
		public loadingCtrl: LoadingController
	) {
		// internet connection
		document.addEventListener('online', () => this.internetConnection$.next(true), false);
		document.addEventListener('offline', () => this.internetConnection$.next(false), false);
	}

	load;
	loading$ = new BehaviorSubject<any>(false);
	async loading(loader){
		try {
			if( this.load ) {
				await this.load.dismiss();
			}

			if(loader) {
				this.load = this.loadingCtrl.create({
					message: 'Caricamento',
					spinner: 'crescent'
				});
				await this.load.present();
				this.loading$.next(true);
			} else {
				this.loading$.next(false);
			}
		} catch(e) {}
	}

	getApiUri(): string {
		if (environment['API_URI'] && environment['API_URI'].length > 0) {
			return environment['API_URI'];
		}
		if (environment.production) {
			return 'https://server.flaaash.delivery/';
		}
		else {
			return 'https://server.flash-delivery.it/'; //url api for testing server
		}
	}

}
