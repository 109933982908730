/*

	user.ts

	Provider for user

	Authors:	Ilia Ashmarin (mail@harduino.com)

	History:	2018.04.12 - File created

*/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

import { CfgProvider } from './cfg';
import { SettingsProvider } from './settings';
import { HelpersProvider } from './helpers';
import { LogProvider } from './log';
import { ApiProvider } from './api';
import { ToastProvider } from './toast';

@Injectable()
export class UserProvider {

	lastAuthState = null;

	settingsData = { xAuthHeader: null };
	authState$ = new BehaviorSubject<any>(null);

	info = {};
	info$ = new BehaviorSubject<any>(null);

	address = null;
	address$ = new BehaviorSubject(null);
	addressInfo = null;
	addressInfo$ = new BehaviorSubject(null);
	xHeaderExists$ = new BehaviorSubject(null);


	constructor(
		public platform: Platform,
		public device: Device,
		public cfg: CfgProvider,
		public log: LogProvider,
		public settings: SettingsProvider,
		public helpers: HelpersProvider,
		public api: ApiProvider,
		public toast: ToastProvider,
		public router: Router
	){
		this.api.user = this;
	}

	// initialise provider
	ngUnsubscribe = new Subject<void>();
	async init() {
		// subscribing for getting actual settings list
		this.settings.data$
			.pipe( takeUntil( this.ngUnsubscribe ) )
			.subscribe((settings) => {
				this.settingsData = settings;
				
				// check for triggering changing auth state
				if( this.lastAuthState === null || this.lastAuthState !== this.isAuthorized() ) {
					this.lastAuthState = this.isAuthorized();
					this.authState$.next( settings['xAuthHeader'] ? true : false );
				}

				// extracting user info and sending to subscribers
				if( this.settingsData['userInfo'] ) {
					try {
						this.info = JSON.parse(this.settingsData['userInfo']);
						this.info$.next( this.info );
					} catch (e) {
						this.log.debug( 'failed to parse local user info', e.message || e );
					}
				}
			});
		// check auth-token with ping-request to server
		await this.checkToken();
	}

	// on app's start check actual-state of token with ping-request to server
	async checkToken() {
		const token = await this.settings.Get('xAuthHeader');
		if (token) {
			try {
				// send auth-ping request
				const pong = await this.api.postAsync('account/ping');

				// show successful message
				if(pong.status === 'success') {
					// our token is actual
				}else{
					this.toast.create('La tua sessione è scaduta, rifare il login!');

					// logout
					this.router.navigate(['logout']);
				}
			} catch(e) {
				this.toast.create(e.toString());
			}
		}
	}

	// return user's auth state
	isAuthorized() {
		return this.settingsData.xAuthHeader !== '' ? true : false;
	}

	// get user actual info from API
	async updateActualInfo() {
	   const response = await this.api.postAsync( 'account/user/info' );
	   if( response.status === 'success' && response.result ) {
		   await this.settings.Set( 'userInfo', JSON.stringify(response.result) );
	   }
	}

	async getTemporaryUserId(){
		let deviceId = 'this.getDeviceToken()';
		if (deviceId) {
			return await this.api.postAsync('shopping/start', {
				"deviceId": deviceId
			});
		}
	}

	async authenticateSettings(settings) {
		await this.settings.SetMulti(settings);
		this.xHeaderExists$.next(true);
		await this.updateActualInfo();
	}

 	userInfo = null;
	userInfo$ = new BehaviorSubject<any>(null);
	async actualizeUserInfo(){
		await this.api.actualize.call(
			this,
			{
				method:		'account/user/info',
				varName:	'userInfo'
			}
		);
	}

	// get user's device token
	getDeviceToken() {
		return this.device.uuid;
	}

	// get user's device type
	getDeviceType() {
		let result = 'web';
		let map = {
			android:	'android',
			ios:		'ios',
			windows:	'windows',
			web:		'mobileweb'
		};
		for(let deviceType in map) {
			let platformCheckName = map[deviceType];
			if(this.platform.is(platformCheckName)) {
				result = deviceType;
				break;
			}
		}

		return result;
	}

	// get user's device platforms
	getPlatforms() {
		return this.platform.platforms();
	}
}
