import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {AlertController, Platform, ToastController} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//providers
import { ApiProvider } from './providers/api';
import { CfgProvider } from './providers/cfg';
import { HelpersProvider } from './providers/helpers';
import { LogProvider } from './providers/log';
import { SettingsProvider } from './providers/settings';
import { ToastProvider } from './providers/toast';
import { CityProvider } from './providers/city';
import { UserProvider } from './providers/user';
import { CourierProvider } from './providers/courier';
import { CourierManagerProvider } from './providers/courierManager';
import { DeliveryProvider } from './providers/delivery';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  userInfo = null;
  settingsData;

  constructor(
    public router: Router,
    public api: ApiProvider,
    public cfg: CfgProvider,
    public helpers: HelpersProvider,
    public log: LogProvider,
    public settings: SettingsProvider,
    public toast: ToastProvider,
    public city: CityProvider,
    public user: UserProvider,
    public courier: CourierProvider,
    public courierManager: CourierManagerProvider,
    public delivery: DeliveryProvider,
	public toastCtrl: ToastController,
	public alertCtrl: AlertController
  ) {
    this.initializeApp();
  }

  ngUnsubscribe: Subject<void> = new Subject<void>();
  async initializeApp() {
	  await this.settings.init();
	  await this.city.init();
	  await this.api.init();
	  await this.user.init();
	  await this.courier.init();
	  await this.courierManager.init();
	  await this.delivery.init();

	  this.settings.data$
		  .pipe(takeUntil( this.ngUnsubscribe ))
		  .subscribe( async settings => {
			  try {
				  this.settingsData = settings;
			  } catch(e) {
				  this.log.debug(e);
			  }
		  })
	  ;

    // when changed user auth state, make redirection
		this.user.authState$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe( async (newAuthState) => {
				if(!newAuthState){
					console.log(this.settingsData.tempUserId === '');
					if(this.settingsData.tempUserId === ''){
						const tempId = await this.user.getTemporaryUserId();
						if(tempId){
							this.settings.SetMulti({
								'tempUserId': tempId.result._id,
								'lastEmail':	'',
								'userType':		'',
								'xAuthHeader':	''
							});
							this.user.xHeaderExists$.next(tempId || false);
						}
					}
				} else if( newAuthState ) {
                        this.router.navigateByUrl('/tabs');
					}
				})
		;

	  // receiving toast command
	  this.toast.create$
		  .pipe(takeUntil( this.ngUnsubscribe ))
		  .subscribe(async (params) => {
			  if( params ) {
				  // default toast params
				  var opts = {
					  message:	'',
					  duration:	this.cfg.timeout.toast
				  };

				  // process arguments
				  if( typeof params === 'string' ) {
					  opts.message = params;
				  } else {
					  opts = params;
				  }
				  // show toast
				  const toaster = await this.toastCtrl.create(opts)
				  toaster.present();
			  }
		  })
	  ;

	  this.toast.alert$
		  .pipe(takeUntil( this.ngUnsubscribe ))
		  .subscribe(async (params) => {
			  if( params ) {
				  // default toast params
				  var opts = {
					  header:'',
					  message:	'',
					  buttons:	['Ok']
				  };

				  // process arguments
				  if( typeof params == 'string' ) {
					  opts.message = params;
				  } else {
					  opts = params;
				  }
				  // show toast
				  const toaster = await this.alertCtrl.create(opts)
				  toaster.present();
			  }
		  })
	  ;
  }
}
