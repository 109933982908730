import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthCheckGuard } from './guards/auth-check.guard';
import { TabsPage } from './tabs/tabs.page';
import {CityCheckGuard} from './guards/city-check.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tabs'
  },
  {
    path: 'tabs',
    component: TabsPage,
    canActivate: [AuthCheckGuard],
    canActivateChild: [AuthCheckGuard],
    children: [
      {
        path: 'ordini',
        canActivate: [CityCheckGuard],
        loadChildren: () => import('./order-tabs/order-tabs.module').then( m => m.OrderTabsPageModule)
      },
      {
        path: 'restaurant-list',
        canActivate: [CityCheckGuard],
        loadChildren: () => import('./restaurant-tabs/restaurant-tabs.module').then( m => m.RestaurantTabsPageModule)
      },
      {
        path: 'corrieri',
        canActivate: [CityCheckGuard],
        loadChildren: () => import('./courier-tabs/courier-tabs.module').then( m => m.CourierTabsPageModule)
      },
      {
        path: 'impostazioni',
        canActivate: [CityCheckGuard],
        loadChildren: () => import('./setting-tabs/setting-tabs.module').then( m => m.SettingTabsPageModule)
      },
      {
        path: '',
        redirectTo: '/tabs/ordini/list',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pagenotfound/pagenotfound.module').then(m => m.PagenotfoundPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
