/*

	redirection.ts

	Provider for work with redirection

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.04.13 - File created

*/

import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable()
export class RedirectionProvider {
    data: any;

    constructor(public navCtrl: NavController) {
        // ...
    }

    push(url: string, data: any = '') {
        this.data = data;

        this.navCtrl.navigateForward('/' + url);
    }

    pop(url) {
        this.navCtrl.navigateBack('/' + url);
    }

    get(key: string) {
        return this.data[key];
    }
    
    back() {
        this.navCtrl.back();
    }
}
// import { Injectable } from '@angular/core';
// import { Subject, BehaviorSubject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';
// import { SettingsProvider } from './settings';
// import { LogProvider } from './log';
// import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
// import { Observable } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
//
// @Injectable()
// export class RedirectionProvider {
//
//
//
// 	constructor(
// 		public settings:SettingsProvider,
// 		public log:LogProvider,
// 		private router: Router,
// 		private route: ActivatedRoute
// 	){}
//
// 	navigate(pageName, param={}) {
// 	 this.router.navigate([pageName], param);
//  }
//
// 	getRoutingData(parameter){}
//
// 	// open new page without saved context
// 	make$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
// 	async make( pageName, params={}, opts={} ) {
// 		this.make$.next({
// 			pageName:	pageName,
// 			params:		params,
// 			opts:		opts
// 		});
// 	}
//
// 	// open new page with "back" button
// 	push$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
// 	async push( pageName, params={}, opts={} ) {
// 		this.push$.next({
// 			pageName:	pageName,
// 			params:		params,
// 			opts:		opts
// 		});
// 	}
//
// 	// restore previous page
// 	pop$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
// 	async pop( prevPageRefresh=false, params={}, opts={} ) {
// 			this.pop$.next({
// 				prevPageRefresh: prevPageRefresh,
// 				params:	params,
// 				opts:	opts
// 			});
// 	}
//
// 	refreshPage$ = new Subject();
// 	refreshPage() {
// 		this.refreshPage$.next();
// 	}
// }
