/*

	toast.ts

	Provider to work with toasts

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.05.04 - File created

*/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

@Injectable()
export class ToastProvider {
	// open new page without saved context
	create$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	async create(params) {
		this.create$.next(params);
	}

	// open new page without saved context
	alert$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	async alert(params) {
		this.alert$.next(params);
	}
}
