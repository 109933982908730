/*

	sort-quarters.ts

	Sort quarters list

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.10.04 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortQuarters',
})
export class SortQuartersPipe implements PipeTransform {
	transform( value ) : any {
		value.sort();
		return value;
	}
}
