/*

	settings.ts

	Provider to work with local settings, saved
	in local storage like SQLITE, WebStorage etc

	Authors:	Matteo Carrara (team@flaaash.it)

	History:	2018.04.06 - File created

*/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

import { CfgProvider } from './cfg';
import { LogProvider } from './log';

@Injectable()
export class SettingsProvider {

	data = {};
	data$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	public carts;

	jsoned = [ 'server', 'courierData', 'courierInfo', 'restData', 'restInfo' ];

	constructor(
		public cfg: CfgProvider,
		public log: LogProvider,
		public storage: Storage
	) {}

	async init() {
		try {
			// waiting for storage ready
			await this.storage.ready();
			// actualize our settings object
			await this.actualize();
		} catch(e) {
			alert( e.message );
		}
	}

	// actualizing our local settings
	async actualize() {
		// adding non-existed settings
		for( let settingName in this.cfg.settings_rows ) {
			let prefixedSettingName = this.getName(settingName);

			// check that setting exists in storage and add with default value if not
			let tryValueFromStorage = await this.storage.get(prefixedSettingName);
			if( tryValueFromStorage === null ) {
				await this.Insert(settingName, this.cfg.settings_rows[settingName]);
			}

			this.data[settingName] = await this.storage.get(prefixedSettingName);
		}

		// sending to subscribers formed settings
		this.actualize_subscribed();
	}

	async Exists( setting_name ) {
		return typeof(this.data[setting_name]) !== 'undefined';
	}

	async Insert( setting_name, setting_value ) {
		await this.Set( setting_name, setting_value );
	}

	async Update( setting_name, setting_value ) {
		await this.Set( setting_name, setting_value );
	}

	async Delete( setting_name ) {
		await this.storage.remove( this.getName(setting_name) );
		if( typeof(this.data[setting_name]) != 'undefined' ) {
			delete this.data[ setting_name ];
		}
	}

	async Get( setting_name ) {
		if( typeof(this.data[setting_name]) != 'undefined' ) {
			return this.data[setting_name];
		}
		return null;
	}

	async Set( setting_name, setting_value ) {
		await this._Set( setting_name, setting_value );
		this.actualize_subscribed();
	}

	async SetMulti( items ) {
		for( let setting_name in items ) {
			let setting_value = items[setting_name];
			this._Set( setting_name, setting_value );
		}
		this.actualize_subscribed();
	}

	async _Set( setting_name, setting_value ) {
		this.data[ setting_name ] = setting_value;
		await this.storage.set( this.getName(setting_name), setting_value );
	}

	async SetServer( serverSettings ) {
		if( serverSettings ) {
			// update settings in local storage if required
			let jsoned = JSON.stringify(serverSettings);
			if( jsoned != JSON.stringify(this.data['server']) ) {
				this.log.debug( 'Update server settings in local storage' );
				await this.storage.set( this.getName('server'), jsoned );
				this.data['server'] = serverSettings;
				this.actualize_subscribed();
			}
		}
	}

	// send to subscribers actual settings list
	actualize_subscribed() {
		// decode jsoned-settings
		for( let i = 0;i < this.jsoned.length;i++ ) {
			let jsonedSettingName = this.jsoned[i];
			if( this.data[jsonedSettingName] && typeof this.data[jsonedSettingName] == 'string' ) {
				try {
					this.data[jsonedSettingName] = JSON.parse(this.data[jsonedSettingName]);
				} catch(e) {}
			}
		}

		this.data$.next( this.data );
	}

	// get prefixed setting's name - we need this name
	// to separate stored values from a several mobile apps
	getName(settingName) {
		return `${this.cfg.settingsPrefix}-${settingName}`;
	}
}
