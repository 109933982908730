/*

	courier.ts

	Provider to work with courier

	Authors:	Ilia Ashmarin (mail@harduino.com)

	History:	2018.07.30 - File created

*/
import {Injectable} from '@angular/core';

import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {CfgProvider} from './cfg';
import {HelpersProvider} from './helpers';
import {SettingsProvider} from './settings';
import {ToastProvider} from './toast';
import {ApiProvider} from './api';
import {UserProvider} from './user';

@Injectable()
export class CourierProvider {
    info = null;
    settingsData = null;
    availability = null;
    availability$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public cfg: CfgProvider,
        public helpers: HelpersProvider,
        public settings: SettingsProvider,
        public toast: ToastProvider,
        public api: ApiProvider,
        public user: UserProvider
    ) {
    }

    async init() {
        this.user.info$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((info) => this.info = info);
        this.settings.data$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((settingsData) => {
                this.settingsData = settingsData;
                const currentAvailability = this.availability$.getValue();
                if (currentAvailability && (currentAvailability.city.cityName !== this.settingsData.citySelected.cityName)) {
                    this.actualizeAvailability();
                }
            });
    }

    async actualizeAvailability() {
        try {
            await this.api.actualize.call(
                this,
                {
                    varName: 'availability',
                    method: 'courierManager/availability/get',
                    data: {
                        cityId: this.settingsData.citySelected._id
                    }
                }
            );
        } catch (e) {
            this.toast.create('Failed to get availability from API-server: ' + e.toString());
        }
    }

    async updateAvailability(newData) {
        await this.api.updateData.call(
            this,
            {
                varName: 'availability',
                method: 'courierManager/availability/set',
                data: newData
            }
        );
    }
}
