import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//PIPE
import { CentsPipe } from './cents.pipe';
import { FilterByFieldPipe } from './filter-by-field.pipe';
import { FilterHasOrdersForQuarterPipe } from './filter-has-orders-for-quarter.pipe';
import { KeysPipe } from './keys.pipe';
import { OrdersForMeNowPipe } from './orders-for-me-now.pipe';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ReversePipe } from './reverse.pipe';
import { SortQuartersPipe } from './sort-quarters.pipe';
import { ValuesPipe } from './values.pipe';
import { FormatHourPipe } from './format-hour.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CentsPipe,
    FilterByFieldPipe,
    FilterHasOrdersForQuarterPipe,
    KeysPipe,
    OrdersForMeNowPipe,
    ReversePipe,
    SortQuartersPipe,
    ValuesPipe,
    FormatHourPipe
  ],
  exports: [
    CommonModule,
    CentsPipe,
    FilterByFieldPipe,
    FilterHasOrdersForQuarterPipe,
    KeysPipe,
    OrdersForMeNowPipe,
    Nl2BrPipeModule,
    ReversePipe,
    SortQuartersPipe,
    ValuesPipe,
    FormatHourPipe
  ],
  providers:[
    SortQuartersPipe,
    KeysPipe,
    ValuesPipe
  ]
})
export class PipesModule { }
