/*

	filter-by-field.ts

	Filter records by specified field

	Authors:	Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.11.23 - File created
*/
import { Pipe, PipeTransform } from '@angular/core';
import { HelpersProvider } from '../providers/helpers';

@Pipe({
	name: 'filterByField',
})
export class FilterByFieldPipe implements PipeTransform {
	constructor(public helpers: HelpersProvider) {};

	transform( values, fieldName, filterObj, changeIndicator ) : any {
		let result = [];
		if (!this.helpers.isEmpty(filterObj)) {
			for (let i = 0;i < values.length;i++) {
				let item = values[i];
				if (item[fieldName]) {
					let found = false;
					for (let j = 0; j < item[fieldName].length; j++) {
						let exists = item[fieldName][j];
						if (filterObj[exists]) {
							found = true;
							break;
						}
					}
					if (found) {
						result.push(item);
					}
				}
			}
		} else {
			result = values;
		}

		return result;
	}
}
