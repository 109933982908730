import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { map, takeUntil, tap} from 'rxjs/operators';

import { CfgProvider } from './cfg';
import { SettingsProvider } from './settings';
import { LogProvider } from './log';
import { ToastProvider } from './toast';
import { RedirectionProvider } from './redirection';
import {HttpClient} from '@angular/common/http';

import md5 from 'md5';
import extend from 'extend';

@Injectable()
export class ApiProvider {

	user;
	constructor(
		public http: HttpClient,
		public log: LogProvider,
		public cfg: CfgProvider,
		public settings: SettingsProvider,
		public toast: ToastProvider,
		public redirection: RedirectionProvider
	){}

	settingsData;
	// initialise provider
	ngUnsubscribe: Subject<void> = new Subject<void>();
	ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	async init() {
		// subscribing for getting actual settings list
		this.settings.data$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe((settings) => {
				this.settingsData = settings;
				this.ready$.next(true);
			})
		;

		// send request to get actual server settings
		await this.updateServerSettings();
	}

	// methods based on HTTP-method
	async get(	method, data= {}, resultType= 'data') { return this.query( 'get', method, data, resultType ); }
	async post(	method, data= {}, resultType= 'data' ) { return this.query( 'post', method, data, resultType ); }
	async delete(	method, data= {}, resultType= 'data' ) { return this.query( 'delete', method, data, resultType ); }
	async auth(	method, data= {}, resultType= 'data' ) { return this.authQuery( 'post', method, data, resultType ); }

	async getAsync(method: string, data = {}, resultType = 'data'): Promise<any> {
		await new Promise<void>((resolve, reject) => {
			this.ready$.subscribe((isReady) => {
				if (isReady) resolve();
			});
		});
		return this.query( 'get', method, data, resultType ).toPromise();
	}

	async postAsync(method: string, data = {}, resultType = 'data'): Promise<any> {
		await new Promise<void>((resolve, reject) => {
			this.ready$.subscribe((isReady) => {
				if (isReady) resolve();
			});
		});
		return this.query( 'post', method, data, resultType ).toPromise();
	}

	async deleteAsync(method: string, data = {}, resultType = 'data'): Promise<any> {
		await new Promise<void>((resolve, reject) => {
			this.ready$.subscribe((isReady) => {
				if (isReady) resolve();
			});
		});
		return this.query( 'delete', method, data, resultType ).toPromise();
	}

	// perform query (common method)
	tokenRenewRunning = false;
	query( type: string, method: string, data: Object, resultType='data' ) {

		let headers = this.addXAuthHeaders();
		const url = this.getApiUrl(method);

		console.log( url, headers, this.settingsData);

		const option = {
			method: type,
			params: {},
			options: {
				body: data,
				headers: headers,
				observe: 'response' as const
			}
		};

		return this.http.request(option.method, url, option.options)
			.pipe(
				tap(async (resp: any) => {

					switch( resultType ) {
						case 'all':		return resp;

						case 'data':
							const parsed = resp;
							// core-handler for special errors
							if (parsed.status && parsed.status === 'error' ) {
								switch(parsed.code) {
									case 'wrong_token':
										if (!this.tokenRenewRunning) {
											this.tokenRenewRunning = true;
											if (this.settings.data['xAuthHeader'] || this.settings.data['tempUserId']) {
												if (this.settings.data['xAuthHeader']) {
													this.toast.create('La tua sessione è scaduta, rifare il Login');

													// renew token if we are registered user
													await this.settings.SetMulti({
														'tempUserId':	'',
														'xAuthHeader':	''
													});
												} else {
													this.toast.create('Stiamo creando una nuova sessione');

													// renew token if we are temporary user
													await this.user.getTemporaryUserId();
													await this.user.tempUserId$
														.subscribe(async (data) => {
															await this.settings.SetMulti({
																'tempUserId':	data.body.result._id,
																'xAuthHeader':	''
															});
														});
												}
											}
											this.tokenRenewRunning = false;
										}
										// protection from multiple-alert windows
										parsed.status = 'success';
										break;
								}
							}
					}
				}),
				map(res =>  res.body)
			);

	}


	async authQuery( type, method, data, resultType='data' ) {
		let headers =  this.addXAuthHeaders();
		const url =  this.getApiUrl(method);
		console.log( url, headers, this.settingsData);
		const option = {
			method: type,
			params:{},
			options:{
				body: data,
				headers: headers,
				observe: "response" as const
			}
		};
		return this.http.request(option.method, url, option.options)
			.pipe(
				tap(async (resp: any) => {
					switch( resultType ) {
						case 'all':	return resp;

						case 'data':
							const parsed = resp;
							// core-handler for special errors
							if (parsed.status && parsed.status === 'error' ) {
								switch(parsed.code) {
									case 'wrong_token':
										if (!this.tokenRenewRunning) {
											this.tokenRenewRunning = true;
											if (this.settings.data['xAuthHeader'] || this.settings.data['tempUserId']) {
												if (this.settings.data['xAuthHeader']) {
													this.toast.create('La tua sessione è scaduta, rifare il Login');

													// renew token if we are registered user
													await this.settings.SetMulti({
														'tempUserId':	'',
														'xAuthHeader':	''
													});
												} else {
													this.toast.create('Stiamo creando una nuova sessione');

													// renew token if we are temporary user
													await this.user.getTemporaryUserId();
													await this.user.tempUserId$
														.subscribe(async (data) => {
															await this.settings.SetMulti({
																'tempUserId':	data.body.result._id,
																'xAuthHeader':	''
															});
														});
												}
											}
											this.tokenRenewRunning = false;
										}
										// protection from multiple-alert windows
										parsed.status = 'success';
										break;
								}
							}
					}
				})
			);

	}



	// context ("this") - who is called
	async actualize( params ) {
		var opts = extend(
			{},
			{
				varName:			'',
				data:				{},
				method:				'',
				methodType:			'postAsync',
				sendSubscribers:	true
			},
			params
		);

		try {
			let varNameSubj = opts.varName+'$';
			let responseData = await this['api'][opts.methodType](opts.method, opts.data);

			// process response from API-server
			if (responseData['status'] === 'success') {
				this[opts.varName] = responseData['result'];
				if (opts.sendSubscribers) {
					this[varNameSubj].next( this[opts.varName] );
				}
			} else {
				this.toast.create(`${opts.method}: ${responseData.message}`);
			}
		} catch(e) {
			console.warn(e);
		}
	}

	// add "X-Auth" header if user is authenticated
	addXAuthHeaders() {
		let headers = {};

		if (this.settingsData.xAuthHeader !== '') {
			headers['X-Auth'] = this.settingsData.xAuthHeader;
		} else if (this.settingsData.tempUserId !== '') {
			headers['X-Temp-Id'] = this.settingsData.tempUserId;
		}
		return headers;
	}

	// form url to API
	getApiUrl(method) {
		return this.cfg.url.api + method;
	}

	// get actual settings from server
	async updateServerSettings() {
		try {
			// calculate hash of current stored settings
			const hash = this.settingsData.server ? md5(JSON.stringify(this.settingsData.server)) : '';

			// send request to API-server
			const response = await this.getAsync('getSettings', {hash : hash });
				if (response.status === 'success') {
					if (response.result) {
						this.settings.SetServer( response.result );
					} else {
						// there are no updates for server's settings
					}
				} else {
					throw new Error(response.message || 'Failed to process response from API-server');
				}
		} catch (e) {
			this.toast.create('Failed to get settings from API-server: ' + (e.message || e));
		}
	}

	// context ("this") - who is called
	async updateData(params) {
		var opts = extend(
			{},
			{
				varName:			'',
				data:				{},
				method:				'',
				methodType:			'postAsync',
				sendSubscribers:	true,
				actualizeUserInfo:	true
			},
			params
		);

		try {

			let varNameSubj = opts.varName+'$';
			let responseData = await this['api'][opts.methodType](opts.method, {data:opts.data});

			// process response from API-server
			if( responseData['status'] === 'success' ){

				this[opts.varName] = responseData['result'];
				console.log('just received data', this[opts.varName]);
				if( opts.sendSubscribers ) {
					console.log('Sending to subscriber', this[varNameSubj]);
					this[varNameSubj].next( this[opts.varName]);
				}
				if( opts.actualizeUserInfo ) {
					await this.user.updateActualInfo();
				}
			} else {
				throw new Error( responseData.message );
			}
		} catch(e) {
			console.warn(e);
			throw e;
		}
	}

	// context ("this") - who is called
	async updateDataAvailableQuarters(params) {
		var opts = extend(
			{},
			{
				varName:			'',
				data:				{},
				method:				'',
				methodType:			'postAsync',
				sendSubscribers:	true,
				actualizeUserInfo:	true
			},
			params
		);

		try {

			let varNameSubj = opts.varName+'$';
			let responseData = await this['api'][opts.methodType](opts.method, {data:opts.data});

			// process response from API-server
			if( responseData['status'] === 'success' ){

				this[opts.varName] = responseData['result'];
				console.log('just received data', this[opts.varName]);
				if( opts.sendSubscribers ) {
					console.log('Sending to subscriber', this[varNameSubj]);
					this[varNameSubj].next( this[opts.varName].availableQuarters);
				}

			} else {
				throw new Error( responseData.message );
			}
		} catch(e) {
			console.warn(e);
			throw e;
		}
	}
}
