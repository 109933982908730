/*

	orders-for-me-now.ts

	Pipe to filter orders by courier and quarter

	Authors:	Ilia Ashmarin (mail@harduino.com)

	History:	2018.05.25 - File created

*/
import { Pipe, PipeTransform } from '@angular/core';

import { HelpersProvider } from '../providers/helpers';

@Pipe({
	name: 'ordersForMeNow'
})
export class OrdersForMeNowPipe implements PipeTransform {
	constructor(
		public helpers:HelpersProvider
	){}

	transform(orders: any[], courier:any, quarter:any ): any[] {
		return orders.filter( order => {
			return order.CourierId == courier.CourierId && this.helpers.isDeliveryBelongsQuarter(order.DeliveryTime, quarter);
		});
	}
}
