import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CityProvider} from '../providers/city';
import {filter, map} from 'rxjs/operators';
import {SettingsProvider} from '../providers/settings';


@Injectable()
export class CityCheckGuard implements CanActivate, CanActivateChild{

    constructor(private settings: SettingsProvider, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
        // return this.settings.data$.pipe(
        //     filter(res => res),
        //     map(data => data.citySelected ? true : this.router.parseUrl('manager'))
        // );
    }


    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
        // return this.settings.data$.pipe(
        //     filter(res => res),
        //     map(data => data.citySelected ? true : this.router.parseUrl('manager'))
        // );
    }
}
