/*

	delivery.ts

	Provider for address

	Authors:	Matteo Carrara (team@flaash.it)
				Ilia Ashmarin (mail[0]harduino.com)

	History:	2018.04.12 - File created

*/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

import * as moment from 'moment';

import { CfgProvider } from './cfg';
import { SettingsProvider } from './settings';
import { HelpersProvider } from './helpers';
import { LogProvider } from './log';
import { ApiProvider } from './api';

@Injectable()
export class DeliveryProvider {

	settingsData;
	info = {
		deliveryType: '',
		addressInfo: {
			address: '',
			cityId: '',
			houseNumber: ''
		}
	};

	info$ = new BehaviorSubject<any>(null);
	constructor(
		public cfg: CfgProvider,
		public log: LogProvider,
		public settings: SettingsProvider,
		public helpers: HelpersProvider,
		public api: ApiProvider
	){}

	// initialise provider
	ngUnsubscribe = new Subject<void>();
	async init() {
		// subscribing for getting actual settings list
		this.settings.data$
			.pipe(takeUntil( this.ngUnsubscribe ))
			.subscribe( settings => this.onSettings(settings) )
		;
	}

	// handler for actual settings
	onSettings(settingsData) {
		this.settingsData = settingsData;

		// extracting city info and sending to subscribers
		if (this.settingsData) {
			if(this.settingsData.deliveryInfo !== {}) {
				try {
					this.info = this.settingsData.deliveryInfo;
					this.info$.next( this.info );
				} catch(e) {
					this.log.debug( 'failed to parse local delivery info', e.message || e );
				}
			}
		}
	}

	// THIS IS NEEDED ON THE INIT TO CHECK IF WE HAVE ALREADY SAVED SOME DATA IN STORAGE OR NOT
	async checkingData(){
		if(this.info.deliveryType === 'delivery' &&   this.info.addressInfo.address && this.info.addressInfo.cityId){
			this.cfg.defaults.pageLoginned = 'TabsPage';
			return true;
		} else if(this.info.deliveryType === 'takeAway'  && this.info.addressInfo.cityId){
			this.cfg.defaults.pageLoginned = 'TabsPage';
			return true;
		} else if(this.info.deliveryType === ''){
			this.cfg.defaults.pageLoginned = 'SelectAddressPage';
			return false;
		}
		return false;
	}

	async setDeliveryOption(deliveryType, address, cityId, houseNumber){
		this.info.deliveryType = deliveryType;

		this.info.addressInfo.address = address;
		this.info.addressInfo.cityId = cityId;
		this.info.addressInfo.houseNumber = houseNumber;

		this.settings.Set( 'deliveryInfo' , this.info );
	}

	async getAddress() {
		return this.info.addressInfo.address;
	}

	async getDeliveryType(){
		return this.info.deliveryType;
	}

	async getCityId(){
		return this.info.addressInfo.cityId;
	}

	async getDeliveryTime(restaurantId, address, deliveryType=null) {
		let result = null;

		const response = await this.api.postAsync('shopping/getDeliveryHour', {restaurantId, address, deliveryType});
		if (response.status === 'success') {
			result = response.result;
		}
		return result;
	}

	getTodayOpenHours(restaurantInfo) {
		let result = null;
		const currentDayOfWeek = moment().format('dddd');
		if(restaurantInfo && restaurantInfo['openHours'] && restaurantInfo['openHours'][currentDayOfWeek]) {
			result = restaurantInfo['openHours'][currentDayOfWeek];
		}
		return result;
	}
}
