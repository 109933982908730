<ion-tabs>

  <ion-tab-bar *ngIf="plat == true " slot="bottom">
    <ion-tab-button tab="ordini" layout="icon-top">
      <ion-icon name="flash"></ion-icon>
      <ion-label>Ordini</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="restaurant-list" layout="icon-top">
      <ion-icon name="apps"></ion-icon>
      <ion-label>Ristoranti</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="corrieri" layout="icon-top">
      <ion-icon name="bicycle"></ion-icon>
      <ion-label>Corrieri</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="impostazioni" layout="icon-top">
      <ion-icon name="build"></ion-icon>
      <ion-label>Impostazioni</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
    <ion-toolbar class="custom-toolbar" *ngIf="!plat" slot="top">
      <ion-row class="ion-justify-content-center ion-align-items-center ion-text-center">
        <ion-col size-xs="12" size-md="4">
          <h2>Flash Manager</h2>
        </ion-col>
        <ion-col size-xs="12" size-md="4">
          <ion-item (click)="router.navigateByUrl('/lista-città')">
              <ion-label>Città</ion-label>
              <ion-label > {{ citySelected }} </ion-label>
            <ion-icon color="primary" item-end name="arrow-down"></ion-icon>
          </ion-item>
        </ion-col>
        <ion-col size-xs="12" size-md="4">

        </ion-col>

      </ion-row>
    </ion-toolbar>
  <ion-tab-bar *ngIf="plat == false" slot="top">
    <ion-tab-button tab="ordini" layout="icon-start">
      <ion-icon name="flash"></ion-icon>
      <ion-label>Ordini</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="restaurant-list" layout="icon-start">
      <ion-icon name="apps"></ion-icon>
      <ion-label>Ristoranti</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="corrieri" layout="icon-start">
      <ion-icon name="bicycle"></ion-icon>
      <ion-label>Corrieri</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="impostazioni" layout="icon-start">
      <ion-icon name="build"></ion-icon>
      <ion-label>Impostazioni</ion-label>
    </ion-tab-button>
  </ion-tab-bar>

</ion-tabs>
